.rdrCalendarWrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrDateDisplay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rdrDateDisplayItem {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0.833em;
}

.rdrDateDisplayItem input {
  text-align: inherit;
}

.rdrDateDisplayItem input:disabled {
  cursor: default;
}

.rdrMonthAndYearWrapper {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rdrMonthAndYearPickers {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rdrNextPrevButton {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrMonths {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rdrMonthsVertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.rdrMonth {
  width: 27.667em;
}

.rdrWeekDays {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rdrWeekDay {
  -ms-flex-preferred-size: calc(100% / 7);
  flex-basis: calc(100% / 7);
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  text-align: center;
}

.rdrDays {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.rdrInfiniteMonths {
  overflow: auto;
}

.rdrDateRangeWrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrDay {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayDisabled {
  cursor: not-allowed;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  pointer-events: none;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  pointer-events: none;
}

.rdrDateRangePickerWrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  flex-direction: row-reverse;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdrStaticRanges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.rdrStaticRange {
  font-size: inherit;
}

.rdrInputRange {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
