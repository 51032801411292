body {
  margin: 0;
  padding: 0;
}
body,
html {
  height: 100%;
  margin: 0;
}
.main {
  display: flex;
  min-height: 100%;
  width: 100%;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  height: 100%;
}
